.root {
  max-width: 512px;
  width: calc(100vw - 32px);
  transition: font-size 600ms cubic-bezier(0.86, 0, 0.07, 1);
}

h1 {
  font-size: 64px;
  margin-bottom: 10px;
}

.info {
  position: relative;
}

.info > p:first-child {
  margin-top: 0;
}
.info > p:last-child {
  margin-bottom: 0;
}

.info + p {
  margin-top: 0;
}

.root.expanded {
  transition-duration: 350ms;
  font-size: 1.20rem;
}
