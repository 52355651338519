@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
  background: #3d3d3d;
  background-image: url('../background.svg');
  background-size: 128px;
  animation: bgmove 5s linear infinite;

  width: 100vw;
  height: 100vh;
  display: flex;
  margin: 0;
  color: white;
  font-family: Roboto, sans-serif;
  flex-direction: column;
  align-items: center;

  overflow-x: hidden;
}

svg {
  user-select: none;
}

@keyframes bgmove {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -128px;
  }
}

.spacer {
  flex: 1;
}

#root {
  margin-bottom: 24px;
}

a {
  color: #6cd5ff;
}
a:not(:hover) {
  text-decoration: none;
}

@media (max-width: 544px) {margin
  #root {
    width: calc(100vw - 32px);
    margin: auto;
  }
}
