@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
.App_root__26snQ {
  max-width: 512px;
  width: calc(100vw - 32px);
  -webkit-transition: font-size 600ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: font-size 600ms cubic-bezier(0.86, 0, 0.07, 1);
}

h1 {
  font-size: 64px;
  margin-bottom: 10px;
}

.App_info__3Et8L {
  position: relative;
}

.App_info__3Et8L > p:first-child {
  margin-top: 0;
}
.App_info__3Et8L > p:last-child {
  margin-bottom: 0;
}

.App_info__3Et8L + p {
  margin-top: 0;
}

.App_root__26snQ.App_expanded__1ZUu0 {
  -webkit-transition-duration: 350ms;
          transition-duration: 350ms;
  font-size: 1.20rem;
}

.Board_root__1bVhV {
  width: 512px;
  height: 512px;
  position: relative;

  margin-bottom: 32px;
}

@media (max-width: 544px) {
  .Board_root__1bVhV svg, .Board_root__1bVhV {
    width: calc(100vw - 32px);
    height: calc(100vw - 32px);
  }
}

.Tile_root__1Dzhz {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 110px;
  height: 110px;
}
.Tile_text__37dg4 {
  font-family: Roboto, sans-serif;
  font-weight: bold;
}

.Tile_c2__YetAB     { --tile-color: #F4F2DF; }
.Tile_c4__rKdyw     { --tile-color: #D0C98C; }
.Tile_c8__2W1FY     { --tile-color: #F8BA5E; }
.Tile_c16__2wovm    { --tile-color: #FD8F3F; }
.Tile_c32__1wxYp    { --tile-color: #FD783F; }
.Tile_c64__2Q75Y    { --tile-color: #FD3F3F; }
.Tile_c128__30k-L   { --tile-color: #FD3FB1; }
.Tile_c256__1krD_   { --tile-color: #C03FFD; }
.Tile_c512__1Kc37   { --tile-color: #713FFD; }
.Tile_c1024__2k2td  { --tile-color: #3F96FD; }
.Tile_c2048__3zG15  { --tile-color: #3FFDA2; }
.Tile_c4096__3SxGT  { --tile-color: #9FFF03; }
.Tile_c8192__1YKDh  { --tile-color: #FAFF03; }
.Tile_c16384__3FoiX { --tile-color: #FFD703; }
.Tile_c32768__1z8Pz { --tile-color: #FF8B03; }
.Tile_c65536__26TXJ { --tile-color: #000000; }

.LoseOverlay_root__3kbA- {
  opacity: 0;
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
  pointer-events: none;
}
.LoseOverlay_lose__27SxD {
  opacity: 1;
  -webkit-transition: opacity 900ms linear 1000ms;
  transition: opacity 900ms linear 1000ms;
  pointer-events: all;
}

.Input_touchDiv__U4wUA {
  -webkit-touch-callout: none;
  -ms-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  touch-action: none;
}

.Header_root__3PTx9 {
  margin-bottom: -14px;
}

@-webkit-keyframes Header_moveUpAndFade__1S0dI {
  0% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }
}

@keyframes Header_moveUpAndFade__1S0dI {
  0% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }
}

.Header_scoreGained__kGeFx {
  -webkit-animation: Header_moveUpAndFade__1S0dI 1s linear forwards;
          animation: Header_moveUpAndFade__1S0dI 1s linear forwards;
}

@media (max-width: 544px) {
  .Header_root__3PTx9 {
    width: calc(100vw - 32px);
    margin-bottom: calc(((100vw - 544px) * 9) / 128 - 14px);
  }
}

body {
  background: #3d3d3d;
  background-image: url(/static/media/background.329d2734.svg);
  background-size: 128px;
  -webkit-animation: bgmove 5s linear infinite;
          animation: bgmove 5s linear infinite;

  width: 100vw;
  height: 100vh;
  display: flex;
  margin: 0;
  color: white;
  font-family: Roboto, sans-serif;
  flex-direction: column;
  align-items: center;

  overflow-x: hidden;
}

svg {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@-webkit-keyframes bgmove {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -128px;
  }
}

@keyframes bgmove {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -128px;
  }
}

.spacer {
  flex: 1 1;
}

#root {
  margin-bottom: 24px;
}

a {
  color: #6cd5ff;
}
a:not(:hover) {
  text-decoration: none;
}

@media (max-width: 544px) {margin
  #root {
    width: calc(100vw - 32px);
    margin: auto;
  }
}

