.root {
  opacity: 0;
  transition: opacity 150ms linear;
  pointer-events: none;
}
.lose {
  opacity: 1;
  transition: opacity 900ms linear 1000ms;
  pointer-events: all;
}
