.root {
  width: 512px;
  height: 512px;
  position: relative;

  margin-bottom: 32px;
}

@media (max-width: 544px) {
  .root svg, .root {
    width: calc(100vw - 32px);
    height: calc(100vw - 32px);
  }
}
